.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 97.5%;
  padding: 20px 20px 20px 5px;
  margin: 15px 0 0 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  border: 1px solid #e9e9e9;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.form-container-inputs-1 {
  display: flex;
  flex-direction: row;
  padding: 15px;
}

.form-container-inputs-2 {
  display: flex;
  flex-direction: row;
  padding: 15px;
}

.search-bar {
  /* margin-left: 15px; */
  padding-right: 0px;
}
